import * as React from "react"
import { graphql } from "gatsby"
import { Seo } from '@components'
import '@css/layout.css'
import '@css/markdown.css'

export default function LegalPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div>
      <Seo title={frontmatter.title} />
      <div className='container max-w-5xl py-12 mx-auto'>
        <h2 className='text-3xl font-bold text-center'>{frontmatter.title}</h2>
        <h2>{frontmatter.date}</h2>
        <div
          className='mt-12'
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        # date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }`